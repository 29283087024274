import { Connection } from "@solana/web3.js"
import { AnchorProvider, Program } from "@coral-xyz/anchor";
import { Locking } from "../types/locking";
import Idl from "../IDL/idl.json";

const progamInstance = (connection: Connection, wallet: any) => {
    const idlData: any = Idl
    const provider = new AnchorProvider(
        connection,
        wallet,
        { preflightCommitment: "processed" }
    );
    const program = new Program(idlData, provider) as Program<Locking>
    return program
}

const initialTierData = [
    {
        tokenRequirement1: 0,
        tokenRequirement2: 0,
        commitmentTime: "5-minutes",
        clientCommission: 50,
        accessToPremiumNodes: false,
        referralProgram: "false"
    },
    {
        tokenRequirement1: 10,
        tokenRequirement2: 25,
        commitmentTime: "5-minutes",
        clientCommission: 30,
        accessToPremiumNodes: true,
        referralProgram: "false"
    },
    {
        tokenRequirement1: 50,
        tokenRequirement2: 100,
        commitmentTime: "5-minutes",
        clientCommission: 30,
        accessToPremiumNodes: true,
        referralProgram: "true"
    },
    {
        tokenRequirement1: 250,
        tokenRequirement2: 500,
        commitmentTime: "5-minutes",
        clientCommission: 30,
        accessToPremiumNodes: true,
        referralProgram: "Enhanced Rates"
    }
];

export { progamInstance, initialTierData }