import { getSTakerProfile, stakeToken } from "../../util/program/staking";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useState, useEffect, useCallback } from "react";
import CountdownTimer from "../Counter/Counter";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import Loader from "../Loader/loader";
import { fetchTokenBalance } from "src/util/program/functions";

const Stake = () => {
	const [amount, setAmount] = useState<number>(0);
	const [Staked, setStaked] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [totalStaked, setTotalStaked] = useState<number>(0);
	const [remainingTime, setRemainingTime] = useState<number>(0);
	const [claimableTokens, setClaimableTokens] = useState<number>(0);
	const [availableToken, setAvailableToken] = useState(0)
	const wallet = useWallet();
	const { publicKey } = useWallet();
	const { connection } = useConnection();

	const getSTakerProfileResponse = useCallback(async () => {
		if (!publicKey?.toBase58()) {
			return;
		}
		setLoading(true);

		const res: any = await getSTakerProfile(connection, wallet);
		if (res) {
			setTotalStaked(res?.totalStakedToken?.toNumber());
			setStaked(res?.stakeAmount?.toNumber());
			setClaimableTokens(res?.claimAmount?.toNumber());
			const time = res?.endLockTime?.toNumber();
			setRemainingTime(time);
		} else {
			setRemainingTime(0);
		}
		const balance = await fetchTokenBalance(publicKey, connection)
		setAvailableToken(balance)
		setLoading(false);
	}, [publicKey?.toBase58()]);

	const stakeTokenFunc = async () => {
		if (!publicKey) {
			enqueueSnackbar("Please connect your wallet", {
				variant: 'error', autoHideDuration: 6000
			})
			return
		}
		setLoading(true);

		await stakeToken(connection, wallet, amount);
		getSTakerProfileResponse();
		setLoading(false);
	};

	useEffect(() => {

		if (publicKey) {
			getSTakerProfileResponse();
		}
	}, [publicKey?.toBase58()]);

	return (
		<>
			{
				loading ?
					(
						<Loader />
					) : (
						<div className="stake-container main-padding-area">
							<div className="container-width">
								<Row>
									<Col lg="5" className="mb-lg-0 mb-sm-4 mb-3">
										<div className="countdown-timer pb-3">
											<div className="countdown-timer-info">
												<h3 className="text-white">Stake Your Tokens</h3>
												<p className="text-white text-white-light mb-0">Lock your tokens to earn rewards and support the network.</p>
											</div>
											<input
												type="number"
												value={amount}
												onChange={(e: any) => setAmount(Number(e.target.value))}
												placeholder="Enter amount"
												className="stake-input mb-3"
												width={"80%"}
												min={0}
											/>
											<p className="text-white text-white-light mb-3"> Token balance {availableToken.toLocaleString('en-US')}</p>
											<button disabled={amount === 0 ? true : false} onClick={stakeTokenFunc} className="btn-green-gradient">
												Stake Token
											</button>
										</div>
									</Col>
									<Col lg="7">
										<div>
											<CountdownTimer
												futureUnixTimestamp={remainingTime}
												claimableTokens={claimableTokens}
												totalStaked={totalStaked}
												Staked={Staked}
											/>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
			}
		</>
	);
};

export default Stake;