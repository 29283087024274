import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { closeStakerProfile } from "../../util/program/functions";
import { unStakeToken } from "../../util/program/unstaking";
import { getSTakerProfile } from "../../util/program/staking";
import CountdownTimer from "../Counter/Counter";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Loader from "../Loader/loader";

const UnStake = () => {
	const [loading, setLoading] = useState(false);
	const [Staked, setStaked] = useState<number>(0);
	const [totalStaked, setTotalStaked] = useState<number>(0);
	const [remainingTime, setRemainingTime] = useState<number>(0);
	const [claimableTokens, setClaimableTokens] = useState<number>(0);

	const wallet = useWallet();
	const { connection } = useConnection();
	const { publicKey } = useWallet();

	const getSTakerProfileResponse = async () => {
		if (!publicKey) {
			return;
		}
		setLoading(true);
		const res: any = await getSTakerProfile(connection, wallet);
		if (res) {
			setTotalStaked(res?.totalStakedToken?.toNumber());
			setStaked(res?.stakeAmount?.toNumber());
			setClaimableTokens(res?.claimAmount?.toNumber());
			const time = res?.endLockTime?.toNumber();
			setRemainingTime(time);
		} else {
			setRemainingTime(0);
		}

		setLoading(false);
	};

	const stakeTokenFunc = async () => {
		if (!publicKey) {
			enqueueSnackbar("Please connect your wallet", {
				variant: 'error', autoHideDuration: 6000
			})
			return
		}
		setLoading(true);

		await unStakeToken(connection, wallet);
		getSTakerProfileResponse();
		setLoading(false);
	};
	useEffect(() => {
		getSTakerProfileResponse();
	}, [publicKey]);

	return (
		<>
			{
				loading ?
					(
						<Loader />
					) : (
						<div className="stake-container main-padding-area">
							<div className="container-width">
								<Row>
									<Col lg="5" className="mb-lg-0 mb-sm-4 mb-3">
										<div className="countdown-timer pb-3">
											<div className="countdown-timer-info">
												<h3 className="text-white">Unstake your tokens</h3>
												<p className="text-white text-white-light mb-0">Withdraw your staked tokens and end the staking commitment.</p>
											</div>
											<button onClick={stakeTokenFunc} disabled={Staked === 0 ? true : false} className="btn-green-gradient mb-3">
												Unstake Token
											</button>
											<button
												disabled={!publicKey}
												className="btn-green-gradient"
												onClick={() => {

													if (!publicKey) {
														enqueueSnackbar("Please connect your wallet", {
															variant: 'error', autoHideDuration: 6000
														})
														return
													}
													closeStakerProfile(connection, wallet)
												}

												}      >
												{" "}
												Close Staker Account
											</button>
										</div>
									</Col>
									<Col lg="7">
										<div>
											<CountdownTimer
												futureUnixTimestamp={remainingTime}
												claimableTokens={claimableTokens}
												totalStaked={totalStaked}
												Staked={Staked}
											/>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
			}
		</>
	);
};

export default UnStake;