import Loader from "../Loader/loader";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useCallback, useEffect, useState } from 'react';
import { getSolanaExplorerLink, getTokenPrice, updateRemainingTime } from 'src/util/program/functions';
import { getAllStakerData } from 'src/util/program/staking';

const StakerHistory = () => {
    const [loading, setLoading] = useState(false);
    const [wifPrice, setWifPrice] = useState<number>(0);
    const [stakerData, setStakerData] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);

    const { connection } = useConnection();
    const wallet = useWallet();
    const { publicKey } = useWallet();
    const fetchPrice = async () => {
        const price = await getTokenPrice();
        setWifPrice(price);
    };

    const getSTakerProfileResponse = useCallback(async () => {
        if (!connection || !publicKey) {
            setError('Connection or wallet is not initialized.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const res: any[] | boolean = await getAllStakerData(connection, wallet);

            if (Array.isArray(res)) {
                setStakerData(res);
            } else {
                setError('Invalid response from the server.');
                setStakerData([]);
            }
        } catch (err: any) {
            console.error('Error fetching staker data:', err);
            setError(err.message || 'An unexpected error occurred.');
            setStakerData([]);
        } finally {
            setLoading(false);
        }
    }, [connection, wallet, publicKey]);

    useEffect(() => {
        fetchPrice()
        if (publicKey) {
            getSTakerProfileResponse();
        }
    }, [publicKey?.toBase58()]);

    return (
        <>
            {
                loading ? (
                    <Loader />
                ) : (

                    <div className="stake-container main-padding-area stake-table">
                        <div className="container-width">
                            <div>
                                <h3 className="text-white mb-4">Staker History</h3>
                                {loading && <p className="text-info">Loading...</p>}
                                {error && <p className="text-danger">{error}</p>}
                                {!loading && !error && (
                                    <div className="table-responsive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Staker Address</th>
                                                    {/* <th>Total Tokens Staked</th> */}
                                                    <th>Current Tokens Staked</th>
                                                    <th>Current Tokens Staked in USD</th>
                                                    <th>Current Tier</th>
                                                    <th>Remaining Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stakerData.length > 0 ? (
                                                    stakerData.map((tier: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <a
                                                                    className="solana-link"
                                                                    href={getSolanaExplorerLink(tier?.account?.identity, "devnet")}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {tier?.account?.identity}
                                                                </a>
                                                            </td>
                                                            {/* <td>{tier?.account?.totalStakedToken}</td> */}
                                                            <td>{(tier?.account?.stakeAmount)} </td>
                                                            <td>{(tier?.account?.stakeAmount * wifPrice).toFixed(2)} $</td>
                                                            <td>{tier?.account?.tier.name}</td>
                                                            <td>{updateRemainingTime(tier?.account?.endLockTime)}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            No staker data available.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default StakerHistory;