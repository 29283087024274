import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { changeOwner } from '../../util/program/functions'
import { PublicKey } from '@solana/web3.js';
import { enqueueSnackbar } from 'notistack';
import Loader from '../Loader/loader';
import { useState } from 'react';

const ChangeVaultOwner = () => {
    const [newOwner, setNewOwner] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false);

    const wallet = useWallet();
    const { connection } = useConnection();

    const validateSolanaAddress = (addr: string) => {
        let publicKey: PublicKey;
        try {
            publicKey = new PublicKey(addr);
            return PublicKey.isOnCurve(publicKey.toBytes());
        } catch (err) {
            return false;
        }
    };

    const onHandleSubmit = async () => {
        if (!validateSolanaAddress(newOwner)) {
            enqueueSnackbar("Please enter a valid wallet address", {
                variant: 'error', autoHideDuration: 6000
            })
            return
        }
        setLoading(true)
        await changeOwner(connection, wallet, newOwner)
        setLoading(false)
    }

    return (
        <>
            {
                loading ?
                    (
                        <Loader />
                    ) : (
                        <div className="stake-container main-padding-area">
                            <div className='small-container'>
                                <div className='tabs-content-wrapper'>
                                    <div className="countdown-timer-info">
                                        <h3 className="text-white">Change Vault Owner</h3>
                                        <p className='text-white text-white-light mb-0'>Type the amount you want to stake</p>
                                    </div>
                                    <div className='formGroup mb-sm-4 mb-3'>
                                        <input
                                            type="string"
                                            value={newOwner}
                                            onChange={
                                                (e: React.ChangeEvent<HTMLInputElement>) => setNewOwner(e.target.value)
                                            }
                                            placeholder="Enter the new owner address"
                                            className="stake-input"
                                            width={"80%"}
                                            min={0}
                                        />
                                    </div>
                                    <button disabled={!wallet.publicKey} onClick={onHandleSubmit} className="btn-green-gradient">
                                        Change owner
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
            }
        </>
    )
}

export default ChangeVaultOwner
