import { Connection, PublicKey, SystemProgram } from "@solana/web3.js"
import { progamInstance } from "./program"
import { BN, utils } from "@coral-xyz/anchor"
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token"
import { enqueueSnackbar } from "notistack"
import { parseError } from "./functions"
import { getAssociatedTokenAddressSync } from "@solana/spl-token"

export const stakeToken = async (connection: Connection, wallet: any, amount: number) => {
    try {
        const program: any = progamInstance(connection, wallet)


        const mintAddress = new PublicKey("36PK2sqvb64dVevhg4xd83mqP2AX5B2mqyyrhtwAciEf")

        const [programStakeAccount, _bump] = PublicKey.findProgramAddressSync([mintAddress.toBuffer()],
            program.programId)

        const [stakerProfile] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("staker_profile"), wallet.publicKey.toBuffer()], program.programId)

        const [vault] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("Vault")],
            program.programId)

        const associatedTokenAccountB = getAssociatedTokenAddressSync(
            mintAddress,
            wallet.publicKey,
            true
        );
        const txHash = await program.methods.stake(_bump, new BN(amount)).accounts({
            programStakeAccount: programStakeAccount,
            vault: vault,
            stakerProfile: stakerProfile,
            identity: wallet.publicKey,
            mintAddress: mintAddress,
            tokenProgram: TOKEN_PROGRAM_ID,
            userStakeAccount: associatedTokenAccountB,
            priceUpdate: new PublicKey("6B23K3tkb51vLZA14jcEQVCA1pfHptzEHFA93V5dYwbT"),
            systemProgram: SystemProgram.programId
        }).signers([]).rpc()
        console.log(txHash, "tx Hash")
        enqueueSnackbar("Token staked successfully", {
            variant: 'success', autoHideDuration: 6000
        })
        return true
    } catch (error: any) {
        const parsedError = parseError(error.message);
        enqueueSnackbar(parsedError.errorMessage || error.message, {
            variant: 'error', autoHideDuration: 6000
        })
        return false

    }
}

export const getSTakerProfile = async (connection: Connection, wallet: any) => {
    try {
        const program = progamInstance(connection, wallet)

        const [stakerProfile] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("staker_profile"), wallet.publicKey.toBuffer()], program.programId)

        const stakerProfileData = await program.account.stakerProfile.fetch(stakerProfile);
        return stakerProfileData
    } catch (error: any) {
        return false

    }
}


export const getAllStakerData = async (connection: Connection, wallet: any) => {
    try {
        const program = progamInstance(connection, wallet)

        const stakerProfileData = await program.account.stakerProfile.all();
        if (stakerProfileData.length > 0) {

            const formattedProfiles = stakerProfileData
                .map(profile => {
                    return {
                        ...profile,
                        account: {
                            ...profile.account,
                            tier: {
                                ...profile.account.tier,
                                tokenRequirements: profile.account.tier.tokenRequirements.map((req: any) => req.toNumber()),
                                commitmentTime: profile.account.tier.commitmentTime.toNumber(),
                                name: !profile.account?.tier.name ? "Tier 0" : profile.account.tier.name
                            },
                            endLockTime: profile.account.endLockTime.toNumber(),
                            timestamp: profile.account.timestamp.toNumber(),
                            claimAmount: profile.account.claimAmount.toNumber(),
                            stakeAmount: profile.account.stakeAmount.toNumber(),
                            totalStakedToken: profile.account.totalStakedToken.toNumber(),
                            updatedTimestamp: profile.account.updatedTimestamp.toNumber(),
                            identity: profile.account.identity.toBase58()
                        }
                    };
                })
                .sort((a, b) => b.account.updatedTimestamp - a.account.updatedTimestamp);

            return formattedProfiles
        } else {
            return false
        }
    } catch (error: any) {
        return false
    }
}