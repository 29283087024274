import { useState } from 'react';
import TierForm from './index';
import UpdateTier from "./update"

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('create');

    return (
        <div className="tabs-container main-padding-area stake-container">
            <div className='small-container'>
                <div className='tabs-content-area text-center mb-3'>
                    <div className="tabs tabs-content-area-info">
                        <button
                            className={`tab ${activeTab === 'create' ? 'active' : ''}`}
                            onClick={() => setActiveTab('create')}
                        >
                            Create
                        </button>
                        <button
                            className={`tab ${activeTab === 'update' ? 'active' : ''}`}
                            onClick={() => setActiveTab('update')}
                        >
                            Update
                        </button>
                    </div>
                </div>

                <div className='tabs-content-wrapper'>
                    <div className="tab-content">
                        {activeTab === 'create' && (
                            <div className="modal-content">
                                <TierForm />
                            </div>
                        )}
                        {activeTab === 'update' && (
                            <div className="modal-content">
                                <UpdateTier />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tabs;
