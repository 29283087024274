import { Connection, PublicKey, SystemProgram } from "@solana/web3.js"
import { progamInstance } from "./program"
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token"
import { BN, utils } from "@coral-xyz/anchor"
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import moment from "moment";
import { getAssociatedTokenAddressSync } from "@solana/spl-token";

const tierNames = ["Tier 0", "Tier 1", "Tier 2", "Tier 3"];


export const initailizeVault = async (connection: Connection, wallet: any, tier: any) => {
    try {
        const program: any = progamInstance(connection, wallet)


        const mintAddress = new PublicKey("36PK2sqvb64dVevhg4xd83mqP2AX5B2mqyyrhtwAciEf")

        const [programStakeAccount] = PublicKey.findProgramAddressSync([mintAddress.toBuffer()],
            program.programId)

        const [vault] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("Vault")],
            program.programId)
        const convertedTierData = tier.map((data: any, index: number) => convertToBN(data, index));

        const txHash = await program.methods.initialize(convertedTierData[0], convertedTierData[1], convertedTierData[2], convertedTierData[3]).accounts({
            programStakeAccount: programStakeAccount,
            vault: vault,
            payer: wallet.publicKey,
            mintAddress: mintAddress,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId
        }).signers([]).rpc()
        console.log(txHash, "tx Hash")
        enqueueSnackbar("Account initailize successfully", {
            variant: 'success', autoHideDuration: 6000
        })
        return true
    } catch (error: any) {
        const errorMessage = parseError(error.message)?.errorMessage || error.message
        enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 6000 })
        return false

    }
}

export const updateVault = async (connection: Connection, wallet: any, tier: any) => {
    try {
        const program = progamInstance(connection, wallet)
        const [vault] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("Vault")],
            program.programId)
        const convertedTierData = tier.map((data: any, index: number) => convertToBN(data, index));
        const txHash = await program.methods.updateTiers(convertedTierData[0], convertedTierData[1], convertedTierData[2], convertedTierData[3]).accounts({
            vault,
            payer: wallet.publicKey,
        }).signers([]).rpc()
        console.log(txHash, "tx Hash")
        enqueueSnackbar("Account update successfully", {
            variant: 'success', autoHideDuration: 6000
        })
        return true
    } catch (error: any) {
        const validationErrors = error?.transactionError?.logs.filter((log: any) => log.includes('Validation error'));
        const errorMessage = parseError(error.message).errorMessage || error.message
        validationErrors && enqueueSnackbar(validationErrors[0], { variant: 'error', autoHideDuration: 6000 })

        enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 6000 })
        return false
    }
}
export const closeStakerProfile = async (connection: Connection, wallet: any) => {
    try {
        const program = progamInstance(connection, wallet)
        const [stakerProfile] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("staker_profile"), wallet.publicKey.toBuffer()], program.programId)
        const txHash = await program.methods.closeStaker().accounts({
            stakerProfile,
            identity: wallet.publicKey
        }).signers([])
            .rpc()
        console.log(txHash, "txHash")
    } catch (error: any) {
        const validationErrors = error?.transactionError?.logs.filter((log: any) => log.includes('Validation error'));
        const errorMessage = parseError(error.message).errorMessage || error.message
        validationErrors && enqueueSnackbar(validationErrors[0], { variant: 'error', autoHideDuration: 6000 })

        enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 6000 })
        return false
    }
}

export const changeOwner = async (connection: Connection, wallet: any, ownerAddress: string) => {
    try {
        const program = progamInstance(connection, wallet)
        const [vault] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("Vault")],
            program.programId)

        const txHash = await program.methods.changeVaultOwner(new PublicKey(ownerAddress)).accounts({
            vault,
            payer: wallet.publicKey,
        }).signers([]).rpc()

        console.log(txHash, "tx Hash")
        enqueueSnackbar("Owner update successfully", {
            variant: 'success', autoHideDuration: 6000
        })
        return true
    } catch (error: any) {
        const validationErrors = error?.transactionError?.logs.filter((log: any) => log.includes('Validation error'));
        const errorMessage = parseError(error.message).errorMessage || error.message
        validationErrors && enqueueSnackbar(validationErrors[0], { variant: 'error', autoHideDuration: 6000 })

        enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 6000 })
        return false
    }
}

export const getVaultData = async (connection: Connection, wallet: any) => {
    try {
        const program = progamInstance(connection, wallet)
        const [vault] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("Vault")],
            program.programId)
        const data = await program.account.vault.fetch(vault);
        const tiers = [convertupdateIntoTiers(data.tierZero), convertupdateIntoTiers(data.tierOne), convertupdateIntoTiers(data.tierTwo), convertupdateIntoTiers(data.tierThree)];
        return tiers
    } catch (error: any) {

        // const validationErrors = error?.transactionError?.logs.filter((log: any) => log.includes('Validation error'));
        // const errorMessage = parseError(error.message).errorMessage || error.message
        // validationErrors && enqueueSnackbar(validationErrors[0], { variant: 'error', autoHideDuration: 6000 })

        // enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 6000 })
        return []
    }
}
export const getVaultAccountData = async (connection: Connection, wallet: any) => {
    try {
        const program = progamInstance(connection, wallet)
        const [vault] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("Vault")],
            program.programId)
        const data = await program.account.vault.fetch(vault);
        const date = new Date(data.updatedTimestamp.toNumber() * 1000); // Multiply by 1000 to convert seconds to milliseconds

        // Get the month, day, and year
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        // Format the date as MM/DD/YYYY
        const formattedDate = `${day}/${month}/${year}`;
        return { stakerCount: data.stakerCount.toNumber(), totalStaked: data.totalStaked.toNumber(), lastTime: formattedDate }
    } catch (error: any) {

        // const validationErrors = error?.transactionError?.logs.filter((log: any) => log.includes('Validation error'));
        // const errorMessage = parseError(error.message).errorMessage || error.message
        // validationErrors && enqueueSnackbar(validationErrors[0], { variant: 'error', autoHideDuration: 6000 })

        // enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 6000 })
        return []
    }
}
const convertupdateIntoTiers = (data: any) => {
    return {
        name: data?.name,
        tokenRequirement1: data?.tokenRequirements[0].toNumber(),
        tokenRequirement2: data?.tokenRequirements[1].toNumber(),
        commitmentTime: data?.commitmentTime.toNumber() == 300 ? "5-minutes" : data?.commitmentTime.toNumber() === 2592000 ? "1-month" : data?.commitmentTime.toNumber() === 15552000 ? "6-month" : "12-month",
        clientCommission: data?.clientCommission,
        accessToPremiumNodes: data?.accessToPremiumNodes,
        referralProgram: data?.referralProgram
    };
}

const convertToBN = (data: any, index: number) => {
    return {
        name: tierNames[index],
        tokenRequirements: [new BN(data.tokenRequirement1), new BN(data.tokenRequirement2)],
        commitmentTime: new BN(convertCommitmentTime(data.commitmentTime)),
        clientCommission: data.clientCommission,
        accessToPremiumNodes: data.accessToPremiumNodes,
        referralProgram: data.referralProgram
    };
};

const convertCommitmentTime = (commitmentTime: string) => {
    let cTime = 0
    switch (commitmentTime) {
        case "5-minutes":
            cTime = 5 * 60;
            break
        case "1-month":
            cTime = 30 * 24 * 60 * 60;
            break
        case "6-month":
            cTime = 6 * 30 * 24 * 60 * 60;
            break
        case "12-month":
            cTime = 12 * 30 * 24 * 60 * 60;
            break
        default:
            cTime = 30 * 24 * 60 * 60;

    }
    return cTime
}


export const parseError = (errorText: string) => {
    const parts = errorText?.split('. '); // Split the text by ". " to break it into parts

    const fileLine = parts[0]?.split(':'); // Extract file and line number
    const errorCode = parts[1]?.split(': ')[1]; // Extract error code
    const errorNumber = parts[2]?.split(': ')[1]; // Extract error number
    const errorMessage = parts[3]?.split(': ')[1]; // Extract error message

    return {
        file: fileLine[0]?.split('/').pop(), // Get the file name (lib.rs)
        line: fileLine[1], // Get the line number
        errorCode: errorCode,
        errorNumber: errorNumber,
        errorMessage: errorMessage,
        transactionHash: null // Assuming the transaction hash isn't provided in the text
    };
};


export const getOwnerAddress = async (connection: Connection, wallet: any) => {
    try {
        const program = progamInstance(connection, wallet)
        const [vault] = PublicKey.findProgramAddressSync([utils.bytes.utf8.encode("Vault")],
            program.programId)
        const data = await program.account.vault.fetch(vault);
        return data.owner.toBase58()
    } catch (error: any) {

        // const validationErrors = error?.transactionError?.logs.filter((log: any) => log.includes('Validation error'));
        // const errorMessage = parseError(error.message).errorMessage || error.message
        // validationErrors && enqueueSnackbar(validationErrors[0], { variant: 'error', autoHideDuration: 6000 })

        // enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 6000 })
        return "GWr5BZALAAGJQNrQMoTvpQ7GfMFjGDvnqabpE2TY9C8C"
    }
}



const COINGECKO_API_URL = 'https://api.coingecko.com/api/v3/simple/price';

export const getTokenPrice = async () => {
    try {
        const response = await axios.get(`${COINGECKO_API_URL}?ids=dogwifcoin&vs_currencies=usd`);
        return response.data["dogwifcoin"].usd;
    } catch (error) {
        console.error('Error fetching token price:', error);
        return null;
    }
};


export const updateRemainingTime = (futureUnixTimestamp: number) => {
    if (futureUnixTimestamp === 0) {
        return "Not started Yet";
    }
    const now = moment();
    const futureDate = moment.unix(futureUnixTimestamp);
    const duration = moment.duration(futureDate.diff(now));

    if (duration.asSeconds() <= 0) {
        return "Time is up!";
        ;
    }

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`
};

export const getSolanaExplorerLink = (address: string, cluster: string = "mainnet") => {
    return `https://explorer.solana.com/address/${address}?cluster=${cluster}`;
};

export const fetchTokenBalance = async (walletAddress: PublicKey, connection: Connection) => {
    try {
        const mintAddress = new PublicKey("36PK2sqvb64dVevhg4xd83mqP2AX5B2mqyyrhtwAciEf")
        const associatedTokenAddress = getAssociatedTokenAddressSync(
            mintAddress, // The mint address
            walletAddress // The wallet address
        );

        console.log("Associated Token Address:", associatedTokenAddress.toBase58());

        // Fetch the token account balance
        const balance = await connection.getTokenAccountBalance(associatedTokenAddress);
        const formattedBalance = (
            Number(balance.value.amount) / (10 ** balance.value.decimals)
        );
        return formattedBalance;

    } catch (error) {
        return 0
    }
}