import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { getOwnerAddress } from "../../util/program/functions";
import toggleIcon from "../../assets/images/toogle-icon.png";
import { Link, useLocation } from "react-router-dom";
import { Image, Nav, Navbar } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import { useEffect, useState } from "react";

const Header = () => {
	const [adminAccount, setAdminAccount] = useState("GWr5BZALAAGJQNrQMoTvpQ7GfMFjGDvnqabpE2TY9C8C");

	const { publicKey } = useWallet();
	const { connection } = useConnection();

	const wallet = useWallet();
	const location = useLocation(); // Get the current route

	const getownerDetails = async () => {
		const address = await getOwnerAddress(connection, wallet);
		setAdminAccount(address);
	};

	useEffect(() => {
		getownerDetails();
	}, []);

	return (
		<header id="header">
			<Navbar expand="lg" className="container-width navbar-wrapper">
				<div className="header-list-area d-flex align-items-center justify-content-between">
					<Link to="/" className="logo">
						<Image src={Logo} alt="logo" fluid />
					</Link>
					<Navbar.Toggle aria-controls="navbarScroll">
						<Image src={toggleIcon} alt="toggleicon" />
					</Navbar.Toggle>
					<Navbar.Collapse id="navbarScroll" className="header-list-wrapper">
						<Nav className="header-list">
							<ul className="d-lg-flex align-items-center justify-content-center m-0 p-0">
								<li className={location.pathname === "/" ? "active" : ""}>
									<Link to="/">Home</Link>
								</li>
								{
									// publicKey?.toBase58() === adminAccount &&
									// (
									<>
										<li className={location.pathname === "/admin" ? "active" : ""}>
											<Link to="/admin">Vault</Link>
										</li>
										<li className={location.pathname === "/change-owner" ? "active" : ""}>
											<Link to="/change-owner">Change Owner</Link>
										</li>
										<li className={location.pathname === "/staker-history" ? "active" : ""}>
											<Link to="/staker-history">Staker History</Link>
										</li>
									</>
									// )
								}
								<li className={location.pathname === "/stake" ? "active" : ""}>
									<Link to="/stake">Stake</Link>
								</li>

								<li className={location.pathname === "/vault" ? "active" : ""}>
									<Link to="/vault">Vault details</Link>
								</li>
								<li className={location.pathname === "/un-stake" ? "active" : ""}>
									<Link to="/un-stake">unStake</Link>
								</li>
							</ul>
						</Nav>
					</Navbar.Collapse>
					<div className="header-wallet-btn">
						<WalletMultiButton />
					</div>
				</div>
			</Navbar>
		</header>
	);
};

export default Header;
