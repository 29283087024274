import { Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import { progamInstance } from "./program";
import { utils } from "@coral-xyz/anchor";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { enqueueSnackbar } from "notistack";
import { parseError } from "./functions";
import { getAssociatedTokenAddressSync } from "@solana/spl-token";

export const unStakeToken = async (connection: Connection, wallet: any) => {
    try {
        const program: any = progamInstance(connection, wallet);

        const mintAddress = new PublicKey(
            "36PK2sqvb64dVevhg4xd83mqP2AX5B2mqyyrhtwAciEf"
        );

        const [programStakeAccount, _bump] = PublicKey.findProgramAddressSync(
            [mintAddress.toBuffer()],
            program.programId
        );

        const [stakerProfile] = PublicKey.findProgramAddressSync(
            [utils.bytes.utf8.encode("staker_profile"), wallet.publicKey.toBuffer()],
            program.programId
        );

        const [vault] = PublicKey.findProgramAddressSync(
            [utils.bytes.utf8.encode("Vault")],
            program.programId
        );

        const associatedTokenAccountB = getAssociatedTokenAddressSync(
            mintAddress,
            wallet.publicKey,
            true
        );
        const txHash = await program.methods
            .unstake(_bump)
            .accounts({
                programStakeAccount: programStakeAccount,
                vault: vault,
                stakerProfile: stakerProfile,
                identity: wallet.publicKey,
                mintAddress: mintAddress,
                tokenProgram: TOKEN_PROGRAM_ID,
                userStakeAccount: associatedTokenAccountB,
                priceUpdate: new PublicKey(
                    "6B23K3tkb51vLZA14jcEQVCA1pfHptzEHFA93V5dYwbT"
                ),
                systemProgram: SystemProgram.programId,
            })
            .signers([])
            .rpc();
        console.log(txHash, "tx Hash");
        enqueueSnackbar("Token staked successfully", {
            variant: "success",
            autoHideDuration: 6000,
        });
        return true;
    } catch (error: any) {
        const parsedError = parseError(error.message);
        enqueueSnackbar(parsedError.errorMessage || error.message, {
            variant: 'error', autoHideDuration: 6000
        })
        return false;
    }
};
