import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getVaultAccountData } from "../../util/program/functions";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loader from "../Loader/loader";

const HomePage = () => {
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState(false);

	const wallet = useWallet();
	const { connection } = useConnection();

	const fetchData = useCallback(async () => {
		setLoading(true);
		const tiersD = await getVaultAccountData(connection, wallet);
		setData(tiersD);
		setLoading(false);
		// eslint-disable-next-line
	}, [wallet, connection]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<>
			{
				loading ?
					(
						<Loader />
					) : (
						<div className="stake-container main-padding-area">
							<div className="container-width">
								<div className="hello-stake-heading">
									<h1 className="text-white">Hello</h1>
									<p className="text-white text-white-light mb-0">Last activity: <span>{data?.lastTime}</span></p>
								</div>
								<Row>
									<Col md={6} lg={4} xl={3} className="mb-md-0 mb-4">
										<div className="countdown-timer">
											<div className="countdown-timer-info">
												<h3 className="text-white">Total Stakers</h3>
												<p className="text-white text-white-light mb-0">The total number of unique users participating in staking.</p>
											</div>
											<span className="text-green">{data?.stakerCount || 0} </span>
										</div>
									</Col>
									<Col md={6} lg={4} xl={3}>
										<div className="countdown-timer">
											<div className="countdown-timer-info">
												<h3 className="text-white">Total Tokens Staked</h3>
												<p className="text-white text-white-light mb-0">The aggregate number of tokens currently locked in staking.</p>
											</div>
											<span className="text-green">{data?.totalStaked || 0} </span>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
			}
		</>
	);
};

export default HomePage;